@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?vaki91');
  src:  url('fonts/icomoon.eot?vaki91#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?vaki91') format('truetype'),
    url('fonts/icomoon.woff?vaki91') format('woff'),
    url('fonts/icomoon.svg?vaki91#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-car:before {
  content: "\e913";
}
.icon-construction:before {
  content: "\e900";
}
.icon-craft:before {
  content: "\e901";
}
.icon-culture:before {
  content: "\e902";
}
.icon-delete:before {
  content: "\e903";
}
.icon-education:before {
  content: "\e904";
}
.icon-electro:before {
  content: "\e905";
}
.icon-energy:before {
  content: "\e906";
}
.icon-enginery:before {
  content: "\e907";
}
.icon-finance:before {
  content: "\e908";
}
.icon-forest:before {
  content: "\e909";
}
.icon-gastronomy:before {
  content: "\e90a";
}
.icon-goods:before {
  content: "\e90b";
}
.icon-health:before {
  content: "\e90c";
}
.icon-house:before {
  content: "\e90d";
}
.icon-organization:before {
  content: "\e90e";
}
.icon-services:before {
  content: "\e90f";
}
.icon-sport:before {
  content: "\e910";
}
.icon-transport:before {
  content: "\e911";
}
.icon-traveling:before {
  content: "\e912";
}
