[data-bs-theme="edb-blue"] { 
  --edb: #262864;
}

[data-bs-theme="edb-cyan"] { 
  --edb: #249aa6;
}

[data-bs-theme="edb-green"] { 
  --edb: #4baf4f;
}

[data-bs-theme="edb-orange"] { 
  --edb: #eb7a58;
}