$font-family-roboto:      "Roboto", sans-serif; 
$font-family-open-sans:   "Open Sans", sans-serif; 

$base-black:          #000;
$base-white:          #fff;

$edb:                   var(--edb); 
$primary:             #262864; 
$secondary:           #fcb75d;

$blue-100:            #b6e0ff;
$blue-400:            #289bf0; 
$blue-500:            #087fd5; 
$blue-600:            #286090;
$blue-700:            #272b5d; 

$neutral-100:         #eeeeee;
$neutral-200:         #d3d3d3;
$neutral-300:         #adadad;
$neutral-400:         #9f9c9c;
$neutral-500:         #777777;
$neutral-600:         #555555;
$neutral-700:         #333333;
$neutral-800:         #1F2937;
$neutral-900:         #111827;

$red-100:             #f2dede;
$red-200:             #ebccd1; 
$red-500:             red; 

$green-100:           #def2de;
$green-200:           #ccebd1;
$green-500:           green;

$yellow:              #ffe883; 

// bootstrap

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$grid-breakpoints: (
  xs: 0,
  xx: 450px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$breadcrumb-font-size:              0.875rem;
$breadcrumb-divider-color:          $neutral-300;
$breadcrumb-divider:                quote(">");

$pagination-padding-y:              0.25rem;
$pagination-padding-x:              0.25rem;

$pagination-font-size:              0.875rem;

$pagination-color:                  $neutral-300;
$pagination-bg:                     transparent;
$pagination-border-width:           0;

$pagination-focus-color:            var(--#{$prefix}link-hover-color);
$pagination-focus-bg:               transparent;
$pagination-focus-box-shadow:       none;

$pagination-hover-color:            var(--#{$prefix}link-hover-color);
$pagination-hover-bg:               transparent;

$pagination-active-color:           $neutral-300;
$pagination-active-bg:              transparent;
