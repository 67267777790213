@import "variables-edb";
@import "fonts";
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";
@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";
@import "transitions";
@import "themes";
@import "~tom-select/src/scss/tom-select.bootstrap5";
@import "~vanillajs-datepicker/sass/datepicker-bs5";
@import "~simplelightbox/src/simple-lightbox";
@import "~leaflet/dist/leaflet.css";
@import "vanilla-cookieconsent/dist/cookieconsent.css";

a { color: inherit; text-decoration: none; transition: all 0.25s; 
  &:hover { color: $primary; }
}
button { background: $edb; border: 1px solid $edb; transition: all 0.25s; }
body { background: $neutral-200; display: flex; flex-direction: column; min-height: 100vh; }
iframe { max-width: 100%; }
hr { border-color: $neutral-200; opacity: 1; }
img { max-width: 100%; }
@include media-breakpoint-down(lg) { 
  html { font-size: 14px; }
}

@for $i from 1 through 9 {
  .bg-neutral-#{$i*100} { background-color: var(--neutral-#{$i*100}); }
}

.fw-500 { font-weight: 500; }
.fw-600 { font-weight: 600; }

@for $i from 1 through 9 {
  .text-neutral-#{$i*100} { color: var(--neutral-#{$i*100}); }
}

.alert { left: 5vw; position: fixed; top: 1rem; right: 5vw; z-index: 10; font-size: 14px;
  &-danger { background-color: $red-100; border-color: $red-200; color: $red-500; }
  &-success { background-color: $green-100; border-color: $green-200; color: $green-500; }
}

.breadcrumb { color: $neutral-300; 
  a:hover { text-decoration: underline; }
  &-item.active { color: $neutral-400; font-weight: bold; }
}

.search-total { color: $neutral-400; font-size: 14px; }

.btn { align-items: center; border-radius: 0.25rem; display: flex; gap: 0.25rem; font-size: 0.875rem; justify-content: center; padding-left: 0.625rem; padding-right: 0.625rem; text-align: center; 
  &-light { color: $primary; 
    &.show { background: $base-white; border-color: $base-white; }
    &:hover { background: $blue-600; border-color: $blue-600; color: $base-white; }
  }
  &-primary:hover { background: $blue-600; border-color: $blue-600; color: $base-white; }
}

.container { max-width: 74.5rem; width: 90%; position: relative; }

.form {
  .hidden { max-height: 20rem; overflow: hidden; transition: max-height 0.5s ease-out; 
    &.collapsed { max-height: 0; }
    &.transitioning { transition: max-height 0.2s ease-in; }
  }
  &-captcha { align-items: start; display: flex; flex-direction: column;
    &-border { border: 1px solid $neutral-200; border-radius: 0.25rem; padding: 0.5rem; } 
  }
  &-captcha--error &-captcha-border { border-color: $red-500; }
  &-check {
    &-input { margin-top: .125em;
      &:checked { background-color: $blue-600; border-color: $blue-600; }
      &:focus { box-shadow: none; }
    }
    &-label { color: $neutral-500; font-size: 0.875rem; 
      a { color: $neutral-600; text-decoration: underline; 
        &:hover { color: $blue-600; }
      }
    }
    &--error &-input { border-color: $red-500; }
    &--required label::before { color: $red-500; content: "* "; }
  }
  &-control { border-color: $neutral-200; }
  &-error { color: $red-500; font-size: 0.75rem; }
  &-file { background: rgba($base-black, 0.02); border: 0; border-radius: 0.25rem; box-shadow: inset 0 -1px 0 rgba($base-black, 0.3); color: rgba($base-black, 0.5); font: 1rem $font-family-roboto; height: 3.5rem; min-height: 3.5rem; padding: 1rem 0.75rem 0; width: 100%;
    &:hover { background: rgba($base-black, 0.04); box-shadow: inset 0 -1px 0 rgba($base-black, 0.5); }
  }
  &-floating {
    > { 
      .form-control { background: rgba($base-black, 0.02); border: 0; border-radius: 0.25rem; box-shadow: inset 0 -1px 0 rgba($base-black, 0.3); height: 3.5rem; min-height: 3.5rem; padding-bottom: 0;
        &:hover { background: rgba($base-black, 0.04); box-shadow: inset 0 -1px 0 rgba($base-black, 0.5); }
        &:focus { padding-bottom: 0; padding-top: 1rem; }
        &:focus ~ label { color: $blue-600; 
          &::after { background: transparent; }
        }
      }
      .form-select { background: rgba($base-black, 0.02); border: 0; border-radius: 0.25rem; box-shadow: inset 0 -1px 0 rgba($base-black, 0.3); color: rgba($base-black, 0.5); font: 1rem $font-family-roboto; height: 3.5rem; min-height: 3.5rem; padding: 1rem 0.75rem 0;
        &:hover { background: rgba($base-black, 0.04); box-shadow: inset 0 -1px 0 rgba($base-black, 0.5); }
        ~ label::after { background: transparent; }
      }
      label { color: rgba($base-black, 0.5); width: 100%;
        &::after { background-color: transparent !important; }
      }
      textarea.form-control { height: auto; }
    }
    &--error > .form-control,
    &--error > .form-select { box-shadow: inset 0 -1px 0 $red-500; }
    &--error > label { color: $red-500; }
    &--error .form-recaptcha div { border-color: $red-500; }
  }
  &-group { color: $neutral-500; font-size: 0.875rem;
    .form-control { box-shadow: inset 0 1px 1px rgba($base-black,.075); font-size: 0.875rem; }
    .form-select { box-shadow: inset 0 1px 1px rgba($base-black,.075); font-size: 0.875rem; }
    &--required label::after { color: $red-500; content: " *"; }
    &--error .form-control,&--error .form-select { border-color: $red-500; }
  }
  &-light { color: $neutral-300; font-size: 0.875rem; }
  &-recaptcha { display: flex; 
    div { border: 1px solid transparent; }
    &--error div { border-color: $red-500; }
  }
  .btn:not(:where(.datepicker *)) { min-width: 13.5rem; text-transform: uppercase; }
}

.page-item {
  &.active { font-weight: bold; }
  &.disabled { display: none; }
}

.text-red { color: $red-500; }

.swiper-container { overflow: hidden; position: relative; z-index: 1; }

.page-bg { position: fixed; width: 100%; height: 100%; }

@import "components";
