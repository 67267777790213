.aside-banner { display: flex; flex-direction: column; justify-content: center; line-height: 1.28; margin: 0 auto 1.25rem; max-width: 300px;
	p { margin: 0;}
	&__arrow { align-items: center; background: $blue-400; color: $base-white; display: flex; font-size: 0.75rem; font-weight: bold; height: 1rem; justify-content: center; line-height: 1; margin-top: auto; transition: all 0.25s; width: 1rem; }
	&__company { align-items: center; box-shadow: inset 0 0 15px rgba($blue-700, 0.5); color: $blue-700; display: grid; font-size: 15px; font-weight: bold; grid-template-columns: repeat(2,1fr); padding: 20px 10px; text-align: center; }
	&__grid { height: 33rem; position: relative; }
	&__image { height: 6.75rem; transition: all 0.25s; }
	&__item { color: $blue-700; position: relative; }
	&__link { align-items: center; animation-duration: 20s; animation-iteration-count: infinite; background: $base-white; border: 2px solid rgba($neutral-300,0.25); display: flex; flex-direction: column; font-size: 0.6875rem; font-weight: bold; gap: 1rem; height: 50%; padding: 1.25rem 0.5rem 0.5rem; position: absolute; text-align: center; width: 50%; transition: all 0.25s; z-index: 1;
		&:nth-child(4n) { animation-name: scaleAnimation4; bottom: 0; right: 0; transform-origin: 100% 100%; }
		&:nth-child(4n + 1) { animation-name: scaleAnimation1; left: 0; top: 0; transform-origin: 0 0; }
		&:nth-child(4n + 2) { animation-name: scaleAnimation2; right: 0; top: 0; transform-origin: 100% 0; }
		&:nth-child(4n + 3) { animation-name: scaleAnimation3; bottom: 0; left: 0; transform-origin: 0 100%; }
	}
}

@keyframes scaleAnimation1 {
  0% { transform: scale(1); z-index: 1; }
  2% { transform: scale(1); z-index: 2; }
  4% { transform: scale(2); }
  25.8% { transform: scale(2); }
  27.8% { transform: scale(1); z-index: 2; }
  27.9% { transform: scale(1); z-index: 1; }
}

@keyframes scaleAnimation2 {
  27.8% { transform: scale(1); z-index: 1; }
  27.9% { transform: scale(1); z-index: 2; }
  29.9% { transform: scale(2); }
  48% { transform: scale(2); }
  50% { transform: scale(1); z-index: 2; }
  50.1% { transform: scale(1); z-index: 1; }
}

@keyframes scaleAnimation3 {
  50% { transform: scale(1); z-index: 1; }
  50.1% { transform: scale(1); z-index: 2; }
  52% { transform: scale(2); }
  70% { transform: scale(2); }
  72.23% { transform: scale(1); z-index: 2; }
  72.3% { transform: scale(1); z-index: 1; }
}

@keyframes scaleAnimation4 {
  72.3% { transform: scale(1); }
  74.3% { transform: scale(2); }
  92.45% { transform: scale(2); }
  94.45% { transform: scale(1); }
}

.basic { color: $neutral-600; display: flex; flex-direction: column; font-size: 0.875rem; gap: 0.625rem;
  &__image { display: flex; height: 100%; margin: 0;
    img { height: 100%; object-fit: cover; width: 100%; }
  }
  &__row { align-items: center; display: grid; gap: 1rem; grid-template-columns: 1fr 2fr; }
  &__text { -webkit-box-orient: vertical; -webkit-line-clamp: 4; display: -webkit-box; margin: 0; overflow: hidden; text-overflow: ellipsis; }
  &__title { align-items: center; -webkit-box-orient: vertical; -webkit-line-clamp: 3; display: -webkit-box; font-size: 0.875rem; margin: 0; overflow: hidden; text-overflow: ellipsis; }
  @include media-breakpoint-down(sm) { padding-left: calc(30vw + 1rem); position: relative;
    &__row { grid-template-columns: none; }
    &__image { left: 0; position: absolute; top: 0; width: 30vw; }
  }
}

.benefits { color: $blue-700; display: grid; gap: 1.25rem; grid-template-columns: repeat(4,1fr); margin-bottom: 2.5rem; margin-top: 2rem;
  h2 { font-size: 1.125rem; }
	&--column { gap: 0; grid-template-columns: none; padding-left: 20px; padding-right: 20px;
		.benefits__item { padding-bottom: 25px; padding-top: 25px; }
		.benefits__item + .benefits__item { border-top: 1px solid $neutral-100; }
	}
	&__item { align-items: center; display: flex; flex-direction: column; font-weight: bold; gap: 10px; text-align: center;
		img { transition: all 0.25s; }
		&:hover img { transform: scale(1.2) rotate(5deg); }
	}
	@include media-breakpoint-down(md) { font-size: 16px; }
	@include media-breakpoint-down(sm) { font-size: 14px; grid-template-columns: repeat(2,1fr);
		&--column { gap: 0; grid-template-columns: none; }
    &__icon { max-width: 40px; }
	}
}

.box { background-color: #fff; border-radius: 0.25rem; box-shadow: 0 0 0 rgba($base-black, 0.3); margin-left: auto; margin-right: auto; margin-bottom: 1.25rem; max-width: 73rem; padding: 1rem; position: relative; width: 90%;
  &::before, &::after { border-radius: 100px / 10px; bottom: 0; box-shadow: 0 0 20px rgba($base-black, 0.2); content: ""; left: 10px; position: absolute; right: 10px; top: 50%; z-index: -1; }
  &__title { color: $neutral-600; font-size: 1.0625rem; font-weight: 500; margin-bottom: 1rem; padding-bottom: 1rem; position: relative; text-align: center; text-transform: uppercase;
    &::after { background-image: linear-gradient(90deg, $neutral-200, $neutral-400, $neutral-200); border: 0; bottom: 0; content: ""; height: 1px; left: 0; margin: 0 auto; position: absolute; right: 0; width: 95%; }
  }
  .carousel-inner { margin-left: auto; margin-right: auto; width: 90%; }
  .carousel-control-next { color: $primary; font-size: 2.5rem; width: 5%; }
  .carousel-control-prev { color: $primary; font-size: 2.5rem; width: 5%; }
}

.branding-banner { position: fixed; height: 100%; width: 100%; background-repeat: no-repeat; background-position: top; top: 0;
	&-fill { background-size: cover; }
}
.branding-banner-spacer { margin-top: 270px; }

.catalog { display: grid; font-size: 0.875rem; gap: 0 2rem; margin-left: auto; margin-right: auto; margin-top: 1.25rem; max-width: 73rem; position: relative; width: 90%;
  &--hp { grid-template-columns: minmax(0,1fr) minmax(0,3fr); margin-bottom: 1.25rem; }
  &--hp &__nav { grid-template-columns: repeat(4,1fr); }
  &__banner { background: $base-white; display: flex; flex-direction: column; flex-grow: 1; justify-content: end; position: relative; text-align: center;
    &-bg { display: flex; height: 100%; left: 0; position: absolute; top: 0; width: 100%;
      img { height: 100%; object-fit: contain; object-position: center bottom; width: 100%; }
    }
    &-title { bottom: 0; color: $base-white; font-size: 0.75rem; left: 0; padding: 0 1rem; position: absolute; right: 0; text-align: center; text-transform: uppercase; }
  }
  &__categories { background: $base-white; border-radius: 0.25rem; display: flex; flex-direction: column; gap: 1rem; justify-content: space-between; padding: 0.625rem 1rem 1rem;
    &-item { align-items: center; color: $neutral-600; display: grid; font-size: 0.875rem; gap: 0.625rem; grid-template-columns: 1.5rem 1fr;
      .icon { font-size: 1.5rem; }
      &:hover { color: $primary; font-weight: bold; }
    }
    &-list { display: grid; gap: 0.3333rem; }
    &-title { color: $primary; font-size: 1rem; text-align: center; text-transform: uppercase; }
  }
  &__slider { overflow: hidden; padding-bottom: 67%; position: relative;
    .swiper-wrapper { bottom: 0; left: 0; position: absolute; right: 0; top: 0; }
    .swiper-pagination { display: flex; flex-direction: column; gap: 0.5rem; justify-content: center; left: auto; right: 1rem; top: 0; width: auto; }
    .swiper-pagination-bullet { background: $base-white; opacity: 0.75; }
    .swiper-pagination-bullet-active { background: $blue-500; }
  }
  &__content { display: flex; flex-direction: column; }
  &__nav { display: grid; font-size: 0.875rem; gap: 0.625rem; grid-template-columns: repeat(4,minmax(0,12rem)); justify-content: center; text-align: center;
    &-item { align-items: center; background: $base-white; border-radius: 0.25rem 0.25rem 0 0; display: flex; justify-content: center; min-height: 2.5rem; padding: 0 0.5rem; text-transform: uppercase;
      &.active { background: $edb; color: $base-white; }
      &:hover { background: $blue-600; color: $base-white; }
    }
    &--strong { gap: 0; grid-template-columns: repeat(4,minmax(0,1fr)); }
    &--strong &-item { background: $edb; color: $base-white;
      &:hover { background: $blue-600; color: $base-white; }
    }
  }
  &__submenu { background: $base-white; bottom: 0; color: $neutral-600; display: none; flex-direction: column; gap: 1rem; padding: 0.625rem 1.5rem; position: absolute; right: 0; top: 0; width: calc((100% - 2rem) * 0.75 + 3rem + 1px); z-index: 2; overflow-y: auto;
    &-columns { columns: 3; column-gap: 1rem; }
    &-item { margin: 0.5rem 0; }
    &-line { background-image: linear-gradient(90deg, $neutral-200, $neutral-400, $neutral-200); border: 0; height: 1px; margin: 0 auto; width: 95%; }
    &-title { font-weight: bold; text-transform: uppercase; }
  }
  &__vip { display: flex; gap: 0.5rem; justify-content: center; text-align: center;
    &-item { border: 1px solid $neutral-200; border-radius: 0.25rem; display: flex; flex-direction: column; padding: 0.3125rem; width: 16%;
      &:hover { background: $neutral-200; }
    }
  }
  @include media-breakpoint-up(xl) {
    &__categories-item:hover + &__submenu,
    &__submenu:hover { display: flex; }
  }
  @include media-breakpoint-down(xl) { gap: 1rem; grid-template-columns: none;
    &__banner { height: 60vw; }
    &__categories-list { grid-template-columns: repeat(4,1fr); }
  }
  @include media-breakpoint-down(lg) {
    &__categories-list { grid-template-columns: repeat(3,1fr); }
  }
  @include media-breakpoint-down(md) {
    &__banner { display: none; }
    &__categories-list { grid-template-columns: repeat(2,1fr); }
    &__nav { grid-template-columns: repeat(2,1fr); margin-bottom: 0.5rem;
      &-item { border-radius: 0.25rem; }
    }
  }
  @include media-breakpoint-down(xx) {
    &__categories-list { grid-template-columns: none; }
    &__nav { grid-template-columns: 100%; }
  }
}

.homepage-cards { margin-bottom: 2rem;
	&__card { padding: 10px 20px; background-color: #ffffff; color: #555555; font-size: 14px; line-height: 20px; border-bottom: 4px solid #262864; height: 100%; transition: 0.5s;
		&:hover { transform: scale(1.05); }
	
		h2 { color: #262864; font-size: 14px; text-transform: uppercase; line-height: 19px; }
	}
}

.homepage-uk-link { display: block; background-color: #262864; color: white; height: 50px; line-height: 50px; text-align: center; font-size: 22px; font-weight: bold; transition: 0.5s;
	&:hover { height: 75px; line-height: 75px; background-color: #286090; color: white; }
}

.company { position: relative; z-index: 1;
    &::after { background: radial-gradient(at top, rgba($base-black,0.3), transparent 70%); bottom: -1rem; content: ""; height: 1rem; left: 0; position: absolute; width: 100%; }
    &__bottom { align-items: center; display: grid; gap: 0.25rem 1rem; grid-template-columns: 1fr 51rem 1fr; padding-bottom: 1px; }
    &__controls { align-items: center; display: flex; gap: 0.5rem; justify-content: flex-end; padding-right: 0.5rem;
		&-item { display: flex; filter: brightness(0); flex-shrink: 0;
			&:hover { filter: none; }
		}
	}
	&__dropdown { flex-shrink: 0; position: relative; z-index: 10;
		&-content { max-height: 0; overflow: hidden; position: absolute; right: -0.375rem; top: calc(100% - 1px); transition: max-height 0.5s ease-out; width: 18.75rem;
      &.slide { max-height: 25rem; }
    }
		&-list { background: $base-white; border: 1px solid $neutral-100; box-shadow: 0.375rem 0.375rem 0.5rem rgba($blue-500, 0.19); display: flex; flex-direction: column; overflow: hidden; padding: 0 0.5rem;
			&-item { color: $blue-500; overflow: hidden; padding: 0.5rem 0 0.5rem 2rem; position: relative; text-overflow: ellipsis; white-space: nowrap;
        &::before { align-items: center; bottom: 0; color: $neutral-400; content: "\F138"; display: flex; font: 0.875rem bootstrap-icons; left: 0.25rem; position: absolute; top: 0; }
      }
			&-item + &-item { border-top: 1px solid rgba($blue-500,0.25); }
		}
		&-toggler { align-items: center; background: $base-white; border: 1px solid $base-white; border-bottom-color: $base-white; display: flex; height: 3rem; justify-content: center; margin: 0 -0.375rem; padding: 0 0.25rem; position: relative; z-index: 1;
			&.active { border-color: $neutral-100; border-bottom-color: $base-white; }
			img { filter: brightness(0); transition: all 0.25s; }
			&:hover img { filter: none; }
		}
	}
	&__languages { display: flex; font-size: 0.875rem; justify-content: center; line-height: 1;
    &-item { border-left: 1px solid $base-black; padding: 0 0.375rem;
			&:hover { color: $secondary; }
			&:first-child { color: $neutral-900; border-left: 0; }
			&.active { font-weight: bold; }
		}
	}
	&__logo { display: flex; margin-right: auto;
	  img { max-width: 500px; max-height: 250px; }
	}
	&__menu { background: $blue-500; color: $base-white; display: flex; font-size: 1.0625rem; gap: 1.25rem; height: 3rem; justify-content: center; padding: 0 1rem; text-align: center; text-transform: uppercase;
    &-item { align-items: center; display: flex; padding: 0.125rem 1rem 0 1rem; position: relative;
			&::before { border-left: 0.5rem solid transparent; border-right: 0.5rem solid transparent; border-top: 0.5rem solid $base-white; content: ""; left: 0; margin: auto; position: absolute; right: 0; top: 0; width: 0; }
			&:hover { background: $blue-500; color: $base-white; }
      &.active { background: $blue-400; }
		}
	}
	&__settings { display: flex; }
	&__top { align-items: center; display: flex; padding: 1.875rem 5.625rem 1.5625rem; }
	&__verified { display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;
		img { width: 196px; }
	}
	@include media-breakpoint-down(xxl) {		&__bottom { grid-template-columns: 1fr auto 1fr; }
		&__menu { gap: 0; }
		&__top { padding: 20px 40px; }
	}
	@include media-breakpoint-down(xl) {		&__bottom { grid-template-columns: none; }
		&__controls { grid-row-start: 1; }
		&__menu { font-size: 15px; }
	}
	@include media-breakpoint-down(md) {		&__logo { margin-bottom: 20px; margin-right: 0; }
		&__menu { flex-direction: column; height: auto; padding: 0;
			&-item { padding-bottom: 10px; padding-top: 10px;
				&::before { bottom: 0; left: 12px; right: auto; transform: rotate(-90deg); }
			}
		}
		&__top { align-items: center; flex-direction: column; padding-left: 0; padding-right: 0; }
	}
}

.company-card { align-items: center; border: 2px solid $neutral-100; display: grid; grid-auto-flow: dense; font-size: 0.6875rem; gap: 1rem; grid-template-columns: 1fr 2px 1fr; margin: 0 auto; max-width: 13.5rem; min-height: 6.5rem; min-width: 10rem; padding: 0.5rem; text-align: center; text-transform: uppercase;
  &::before { align-self: stretch; background: $neutral-100; display: block; content: " "; grid-column: 2; width: 2px; }
  &:hover { color: $blue-600; text-decoration: underline; }
}

.contact-bar { background: $base-white; box-shadow: 0 0.25rem 0.5rem 0 rgba($base-black, 0.15); display: none; flex-wrap: wrap; gap: 0.75rem 3rem; justify-content: flex-end; left: 0; margin-left: auto; margin-right: auto; max-width: 73rem; padding: 0.5rem 5vw; position: fixed; right: 0; top: 0; width: 90%; z-index: 5;
  p { margin: 0; }
	&.active { display: flex; }
	&__item { align-items: center; display: grid; gap: 0.75rem; grid-template-columns: 25px 1fr; 
    img { width: 1.5rem; }
  }
  @include media-breakpoint-down(md) { gap: 0.5rem 1rem; 
    &__item { gap: 0.375rem; }
  }
}

.contacts { display: flex; flex-direction: column; gap: 0.125rem; line-height: 1.2;
  p { margin-bottom: 0; }
	&--row { flex-direction: row;
		.contacts__heading { display: none; }
		.contacts__icon { left: 1.5rem; width: 1.875rem; }
		.contacts__item { flex: 1 0 0; font-size: 1rem; height: 3.75rem; }
	}
	&__heading { color: $secondary; font-size: 0.875rem; text-transform: uppercase; }
	&__icon { bottom: 0; left: 1.25rem; margin: auto; position: absolute; top: 0; }
	&__item { background: $neutral-200; display: flex; flex-direction: column; font-size: 0.875rem; gap: 0.5rem; height: 5.5rem; justify-content: center; padding-left: 5.5rem; position: relative;
    &:hover { background: $base-white; }
	}
	@include media-breakpoint-down(xl) {		&--row { flex-direction: row; flex-wrap: wrap;
			.contacts__icon { left: 0.625rem; }
			.contacts__item { font-size: 0.875rem; padding-left: 3.125rem; }
		}
		&__icon { left: 0.625rem; width: 2.1875rem; }
		&__item { height: 4.375rem; padding-left: 3.75rem; }
	}
	@include media-breakpoint-down(lg) {
    &--row &__item { height: 2.5rem; }
	}
}

.demands { display: grid; gap: 0.375rem; grid-template-columns: repeat(5,1fr);
  &--demand-types { grid-template-columns: repeat(4,1fr); }
  &__item { align-items: center; border: 1px solid $neutral-200; border-radius: 0.25rem; display: flex; flex-direction: column; gap: 0.5rem; padding: 0.625rem; text-align: center; }
  &__item:hover &__heading { color: $primary; }
  &__text { display: none; font-size: 0.875rem; margin: 0; }
  &__heading { color: $neutral-600; font-size: 0.875rem; font-weight: normal; margin-bottom: 0; }
  @include media-breakpoint-down(md) { grid-template-columns: none;
    &__image { left: 1rem; position: absolute; top: 1rem; width: 5rem; }
    &__item { align-items: start; justify-content: center; min-height: 7rem; padding-left: 7rem; position: relative; text-align: left; }
    &__text { display: block; }
  }
}

.detail-basic { display: grid; gap: 1.625rem; grid-template-columns: 36% 1fr; line-height: 1.2; padding: 0.9375rem 0;
    p { margin-top: 1em; }
	&--small { font-size: 0.875rem; gap: 1rem; padding-bottom: 0.625rem; padding-top: 0.625rem;
    p { margin-top: 0.5em; }
		.detail-basic__title { font-size: 0.9375rem; font-weight: 600; }
		.detail-basic__image { height: 7.1875rem; margin-top: 0.3125rem; 
			> img { padding: 0; }
		}
	}
	&__date { color: $neutral-300; }
	&__image { display: flex; height: 11.25rem; margin-top: 0.3125rem;
	  > img { height: 100%; object-fit: contain; width: 100%; padding: 0 1.5rem; }
  	}
	&__text { display: flex; flex-direction: column; gap: 0.25rem; }
	&__title { color: $blue-500; font-size: 1.5rem; margin-bottom: 0; transition: all 0.25s; }
	@include media-breakpoint-down(xl) {		&__title { font-size: 1.25rem; }
	}
	@include media-breakpoint-down(sm) { grid-template-columns: none;
    &--small { grid-template-columns: 36% 1fr; }
		&__image { height: 50vw; }
	}
}
a.detail-basic:hover .detail-basic__title { color: $secondary; }

.detail-card { align-items: start; background: $neutral-100; display: grid; font-size: 14px; line-height: 1.5; gap: 28px; grid-template-columns: 190px 1fr 1.75fr; margin-bottom: 50px; margin-left: -70px; margin-right: -60px; padding: 28px 60px 28px 70px;
  a:hover { color: $secondary; }
&__heading { font-size: 0.875rem; font-weight: bold; text-transform: uppercase; }
&__image { display: flex; border: 4px solid $base-white;
  > img { height: 100%; object-fit: cover; width: 100%; }
}
&__item { padding-left: 4.5rem; margin-bottom: 1.5rem; position: relative;
  &-icon { left: 0; position: absolute; top: 0; }
}
&__social { display: flex; flex-wrap: wrap; gap: 0.75rem; margin-bottom: 0.375rem; margin-top: 0.5rem;
  &-item { opacity: 0.5;
    img { max-width: 3rem; filter: brightness(0); }
    &:hover { opacity: 0.75; }
  }
}
@include media-breakpoint-down(xxl) { margin-left: -2.5rem; margin-right: -2.5rem; padding: 2.5rem; }
@include media-breakpoint-down(md) { grid-template-columns: 12rem 1fr; margin-left: -5vw; margin-right: -5vw; padding-left: 5vw; padding-right: 5vw;
  &__main { grid-column: span 2; }
}
@include media-breakpoint-down(sm) { grid-template-columns: none;
  &__main { grid-column: auto; }
}
}

.detail-cnt { background: $base-white; box-shadow: 0 0 30px rgba($base-black, 0.2); margin-left: auto; margin-right: auto; max-width: 73rem; position: relative; width: 90%;
  &__content { overflow: hidden; transition: all 0.25s; }

  &__article { display: flex; flex-direction: column; font-size: 1.0625rem;
		h2 { color: $blue-500; font-size: 1.0625rem; font-weight: bold; text-transform: uppercase; }
		h3 { color: $blue-500; font-size: 1.0625rem; font-weight: bold; }
		h4 { color: $blue-500; font-size: 0.9375rem; font-weight: bold; }
		
		ol { counter-reset: section; list-style: none; padding: 0;
			li { padding-left: 1.5rem; position: relative;
				&::before { color: $blue-500; content: counter(section) "."; counter-increment: section; font-size: 0.875rem; font-weight: bold; left: 0; position: absolute; top: 0.25rem; }
			}  
		}
		
		p a { color: $blue-500; text-decoration: underline; 
			&:hover { text-decoration: none; }
		}
		
		table { margin-bottom: 1em;
			a { color: $blue-500; text-decoration: underline;
				&:hover { text-decoration: none; }
			}
			tbody {
        td { border: 1px solid $neutral-100; padding: 0.125rem 0.625rem; vertical-align: top; }
				th { border: 1px solid $neutral-100; font-weight: bold; padding: 0.125rem 0.625rem; text-align: left; vertical-align: top; }
			}
      thead th { background: $neutral-100; padding: 0.125rem 0.625rem;  }

	      &.no-border {
		    th, td { border: none; }
		  }
		}
		ul { list-style: none; padding-left: 0;
      li { padding-left: 1.5rem; position: relative;
        &::before { color: $blue-500; content: "• "; left: 0; position: absolute; }
      }
    }
	}
	&__button { align-self: flex-start; display: flex; margin-bottom: 2.5rem; margin-top: 0.5rem;
		&--center { align-self: center; }
    &-item { background: $blue-400; color: $base-white; padding: 0.5rem 3.75rem; position: relative; text-transform: uppercase;cursor: pointer;
			&::after { background: radial-gradient(at top, rgba($base-black,0.3), transparent 70%); bottom: -1.625rem; content: ""; height: 0.9375rem; left: 0; position: absolute; width: 100%; }
			&.active .detail-cnt__button-toggle { display: none; }
			&.active .detail-cnt__button-toggle + .detail-cnt__button-toggle { display: inline; }
		}
		&-toggle + &-toggle { display: none; }
		&:hover { color: $secondary; }
	}
	&__collapsed { max-height: 400px;  }
	&__columns { display: grid; grid-template-columns: minmax(0,1fr) 25rem; }
	&__grid { display: grid; gap: 1.1875rem 1.8125rem; grid-template-columns: repeat(2,1fr); }
	&__header { display: flex; flex-direction: column; gap: 0.9375rem; }
	&__hidden-desktop { display: none; }
	&__hr { background: radial-gradient(at top, rgba($base-black,0.1), transparent 70%); border: 0; height: 1.6875rem; margin: 3.125rem -3.75rem 0 -5.625rem; }
	&__image { margin: 0.625rem 0; position: relative;
		&-item { display: flex;
      > img { height: 100%; object-fit: cover; width: 100%; }
    }
		//&-zoom { background: url("img/icon--zoom.svg") no-repeat center center,radial-gradient($base-white 10%,transparent 75%); bottom: 0; content: ""; height: 4.0625rem; position: absolute; right: 0; width: 4.0625rem; }
	}
	&__primary { padding: 3.375rem 3.75rem 3.75rem 4.375rem; }
	&__subtitle { color: $neutral-400; font-size: 1.0625rem; font-weight: 400; text-transform: uppercase; }
	&__title { color: $blue-700; font-size: 2rem; font-weight: 800; margin-bottom: 0.9375rem; }
	&__video { display: flex; flex-direction: column; gap: 10px; margin-bottom: 31px;
    iframe { border: 2px solid $neutral-300; display: flex; height: 415px; max-width: 620px; width: 100%; }
		strong { text-transform: uppercase; }
	}
	@include media-breakpoint-down(xxl) {		&__hr { margin-left: 0; margin-right: 0; }
		&__primary { padding: 2.5rem; }
	}
	@include media-breakpoint-down(xl) {		&__article { font-size: 1rem; }
		&__columns { grid-template-columns: minmax(0,1fr) 18.75rem; }
		&__title { font-size: 1.5625rem; }
	}
	@include media-breakpoint-down(lg) {		&__columns { grid-template-columns: 100%; }
	}
	@include media-breakpoint-down(md) { box-shadow: none;
    &__button { justify-content: center; }
		&__grid { grid-template-columns: none; }
		&__hidden-desktop { display: block; }
		&__hidden-mobile { display: none; }
		&__primary { padding-left: 0; padding-right: 0; }
		&__video iframe { height: 50vw; }
	}
	@include media-breakpoint-down(sm) {		&__article-footer { align-items: stretch; flex-direction: column; }
	}
}

.directions { margin-right: 0.625rem;
  p { margin: 0; }
  &__box { align-items: center; background: $base-white; border-radius: 0.9375rem; box-shadow: 0 0.25rem 0.5rem 0 rgba($base-black, 0.15); display: flex; flex-direction: column; gap: 1.25rem; padding: 2.25rem; position: relative; text-align: center; }
  &__form { background: $blue-400; border-radius: 0 0 1rem 1rem; box-shadow: 0.3125rem 0.3125rem 0.5625rem rgba($blue-700, 0.19); color: $base-white; margin-top: -0.9375rem; padding-top: 0.9375rem;
    &-bottom { display: flex; justify-content: center; padding: 1.25rem 1.25rem 1.875rem; }
    &-row { align-items: center; border-bottom: 1px solid $blue-500; display: flex; gap: 10px; min-height: 48px; padding: 10px 30px;
      input { background: transparent; border: 0; flex-grow: 1; }
    }
    &-submit { background: $blue-100; border: 0; color: $blue-500; max-width: 240px; min-height: 36px; text-transform: uppercase; width: 100%;
      &:not(:disabled):hover { background: $blue-700; color: $base-white; }
	  &:disabled { opacity: 0.5; }
    }
    &-title { align-items: center; border-bottom: 1px solid $blue-500; display: flex; font-size: 15px; gap: 15px; padding-bottom: 6px; padding-left: 30px; padding-right: 30px; padding-top: 17px; text-transform: uppercase; }
    .smap-suggest { color: unset; }
  }
  &__gps { align-items: flex-end; display: flex; flex-wrap: wrap; font-size: 18px; gap: 15px;
    &-strong { color: $blue-500; font-size: 14px; font-weight: bold; }
  }
  &__qr { max-width: 12.5rem; }
  &__title { color: $neutral-300; font-size: 17px; margin: 0; padding: 15px; text-transform: uppercase; }
  @include media-breakpoint-down(xs) {
    &__box { padding-left: 15px; padding-right: 15px; }
    &__form {
      &-row { padding-left: 15px; padding-right: 15px; }
      &-title { padding-left: 15px; padding-right: 15px; }
    }
  }
}

.documents { display: flex; flex-direction: column; gap: 1.875rem; padding: 1.25rem 1.25rem 4.625rem;
    &__item { border: 1px solid $neutral-200; display: flex; margin: 0 auto; max-width: 13.9375rem;
    > img { height: 100%; object-fit: cover; width: 100%; }
  }
	&__title { color: $neutral-300; font-size: 1.0625rem; text-transform: uppercase; }
}

.footer { background: $edb; color: $base-white; margin-top: map-get($spacers, 2); padding-bottom: 1rem; padding-top: 1rem; position: relative; text-align: center;
  a:hover { color: inherit; text-decoration: underline; }
  &__container { display: grid; gap: 1rem; grid-template-columns: 14.25rem 1fr; }
  &__nav { display: flex; flex-wrap: wrap; font-size: 0.875rem; gap: 0.25rem 1.25rem; justify-content: center; padding: 0.5rem 0; }
  @include media-breakpoint-down(lg) {
    &__nav--lg-hidden { display: none; }
  }
  @include media-breakpoint-down(md) {
    &__container { grid-template-columns: 10rem 1fr; }
  }
  @include media-breakpoint-down(sm) {
    &__logo { margin-left: auto; margin-right: auto; max-width: 10rem; }
    &__container { grid-template-columns: none; }
  }
}

.form {
  &__bg-input { background: $neutral-100; display: flex; margin-bottom: 0.9375rem; min-height: 2.625rem;
    input, select { background: transparent; border: 0; flex-grow: 1; padding: 0; }
		label { align-items: center; display: flex; height: 1.875rem; justify-content: center; margin-top: 0.375rem; width: 3rem; }
		textarea { background: transparent; border: 0; flex-grow: 1; padding-top: 0.625rem; }
		&--border { background: $base-white; border: 1px solid $neutral-400; }
	}
	&__captcha { background: $neutral-100; display: flex; gap: 0.75rem; margin-bottom: 0.9375rem; min-height: 4.25rem;
    input { background: transparent; border: 0; flex-grow: 1; padding: 0; }
		label { padding: 0.5rem; }
	}
	&__checkbox { color: $neutral-300;
    label a { color: $neutral-600; }
	}
	&__footer { display: flex; gap: 0.625rem; justify-content: flex-end; margin-top: 1.25rem;
    &-button { background: linear-gradient($yellow,$secondary); border: 0; border-radius: 0.3125rem; color: $neutral-700; gap: 0.9375rem; padding: 0.5625rem 1.25rem; text-transform: uppercase;
			&--secondary { background: $blue-500; border-radius: 0; color: $base-white;
				&:hover { background: $blue-700; }
			}
		}
		&-link { color: $blue-500; font-size: 0.875rem; margin-right: auto; text-decoration: underline;
			&:hover { text-decoration: none; }
		}
	}
	&__row { display: grid; gap: 0 1.25rem; grid-template-columns: repeat(2,1fr); }
	&__title { color: $neutral-300; font-size: 1.0625rem; font-weight: 600; margin-bottom: 0.75rem; text-transform: uppercase; }
	@include media-breakpoint-down(md) {		&__row { grid-template-columns: none; }
	}
	@include media-breakpoint-down(sm) {		&__footer { align-items: flex-start; flex-direction: column; }
	}
}

.gallery { display: grid; gap: 0.9375rem 1.25rem; grid-template-columns: repeat(2,minmax(0,1fr)); margin-bottom: 0.375rem;
    &--columns-3 { grid-template-columns: repeat(3,minmax(0,1fr));
		.gallery__item { height: 9.375rem; }
	}
	&__item { border: 0.0625rem solid $neutral-300; display: flex; height: 15.625rem; position: relative;
    > img { height: 100%; object-fit: cover; width: 100%; }
		&:hover .gallery__text { opacity: 1; }
	}
	&__text { align-items: center; background: rgba($blue-700,0.75); bottom: 0; color: $base-white; display: flex; font-size: 0.8125rem; justify-content: center; left: 0; opacity: 0; padding: 0.9375rem; position: absolute; right: 0; text-align: center; top: 0; transition: all 0.25s; }
	&__zoom { align-items: center; background: radial-gradient($base-white 10%,transparent 75%); bottom: 0; color: $blue-500; content: ""; display: flex; font-size: 2rem; height: 4.0625rem; justify-content: center; position: absolute; right: 0; width: 4.0625rem; }
	@include media-breakpoint-down(lg) {		&--columns-3 { grid-template-columns: repeat(3,minmax(0,1fr));
			.gallery__item { height: 30vw; }
		}
		&__item { height: 30vw; }
	}
	@include media-breakpoint-down(sm) { gap: 5px; }
}

.header { background: $edb; color: $base-white;
  &__container { align-items: center; display: flex; gap: 1rem; min-height: 4.375rem; margin-left: auto; margin-right: auto; max-width: 73rem; width: 90%; }
  &__form { display: flex; gap: 0.625rem; height: 2.1875rem;
    &-group { display: grid; gap: 0.5rem; grid-template-columns: 1fr 2fr;
      & + & { border-top: 1px solid $neutral-200; margin-top: 0.625rem; padding-top: 0.625rem; }
      label { color: $neutral-400; font-size: 0.875rem; font-weight: bold; }
    }
    &-input { background: $base-white; border-radius: 2px; display: flex; position: relative;
      > input { background: transparent; border: none; font-size: 0.875rem; padding: 0 0.75rem; width: 16.25rem;
        &::placeholder { color: $neutral-300; opacity: 1; }
      }
      .dropdown-menu { border: 1px solid $neutral-200; border-radius: 0; box-shadow: 0 0.375rem 0.75rem rgba($base-black,.175); padding: 0.625rem;
        &.show { inset: calc(100% - 1px) 0 auto 0 !important; margin: 0 !important; transform: none !important; }
      }
      .dropdown-toggle { background: transparent; border: none; border-left: 1px solid rgba($base-black,0.075); border-radius: 0 2px 2px 0; color: $primary; font-size: 0.875rem; padding: 0 0.625rem;
        &::after { content: none; }
        &:hover { background: $blue-600; color: $base-white; }
      }
    }
    &-parameters { display: flex; flex-direction: column; gap: 0.625rem;
      input, option, select { color: $neutral-600; font-family: $font-family-roboto; font-size: 0.875rem; line-height: 1.2; padding: 0.25rem; }
    }
    &-radio { align-items: start; display: flex; gap: 0.5rem;
      input { margin-top: 4px; }
      label { color: $neutral-400; cursor: pointer; font-weight: normal; }
    }
  }
  &__languages { position: relative;
    .dropdown-menu { border: 1px solid $neutral-200; border-radius: 0; padding: 0;
      &.show { margin: 0 !important; top: calc(100% - 1px) !important; transform: none !important; }
    }
    &-link { color: $neutral-400; font-size: 0.875rem;
      &:hover { color: $blue-600; text-decoration: underline; }
    }
    &-list { display: flex; flex-direction: column; padding: 0.625rem; width: 9rem; }
  }
  &__logo { display: flex; margin-right: auto; width: 14rem; }
  @include media-breakpoint-down(md) {
    &__container { gap: 1rem; min-height: 3.5rem; }
    &__form { height: 2rem;
      &-input .dropdown-toggle { font-size: 0;
        &::before { content: "\F282"; font: 0.875rem bootstrap-icons; }
      }
      &-search { font-size: 0;
        &::before { content: "\F52A"; font: 0.875rem bootstrap-icons; }
      }
    }
    &__languages .dropdown-toggle { height: 2rem; }
    &__logo { width: 10rem; }
  }
  @include media-breakpoint-down(sm) {
    &__container { flex-direction: column; padding-bottom: 1rem; padding-top: 1rem; }
    &__logo { margin-right: 0; }
  }
}

.header-detail { background: $primary; color: $base-white; padding-bottom: 0.5rem; padding-top: 0.5rem; position: relative; text-align: center; z-index: 10;
    a:hover { color: $secondary; }
	&__container { align-items: center; display: flex; gap: 1.625rem; margin: 0 auto; max-width: 73rem; width: 90%; }
	&__dropdown { align-items: center; align-self: stretch; display: flex; position: relative; text-transform: uppercase;
    &-content { background: $primary; max-height: 0; overflow: hidden; position: absolute; right: 0; top: 100%; transition: max-height 0.5s ease-out; width: 12rem;
      &.slide { max-height: 20rem; }
    }
		&-toggler { background: transparent; border: 0; display: flex;
			&:hover { filter: brightness(100); }
		}
	}
	&__logo { display: flex; margin-right: auto; max-width: 6.875rem;
  }
	&__menu { display: flex; flex-direction: column; font-size: 0.75rem; gap: 0.625rem; padding: 1.25rem; }
	&__nav { background: $primary; display: flex; font-size: 0.75rem; gap: 0.625rem 1.875rem; text-transform: uppercase; }
	&__overlay { left: 0; position: fixed; right: 0; top: 0;
    &.active { bottom: 0; }
	}
  &--large &__logo { max-width: 14.3125rem; }
  &--large &__nav { font-size: 0.875rem; }
	@include media-breakpoint-down(md) {		&__nav { display: none; flex-direction: column; padding: 1.25rem; position: absolute; right: 0; top: 100%; }
	}
}

.heading { color: $neutral-500; display: flex; flex-direction: column; font-size: 0.875rem; font-weight: bold; justify-content: center; min-height: 8rem; padding-left: 10rem; position: relative;
  &__image { border-radius: 50%; height: 8rem; left: 0; object-fit: cover; position: absolute; top: 0; width: 8rem; }
  &__title { color: $primary; font-size: 1.625rem; font-weight: normal; }
}

.highlight { background: $primary; color: $base-white; padding: 1rem;
  a { text-decoration: underline;
    &:hover { color: $base-white; text-decoration: none; }
  }
}

.image-flip { display: grid;
  &__backside { align-items: center; backface-visibility: hidden; background: $primary; border-radius: 6px; box-shadow: 5px 7px 9px -4px $neutral-400; color: $base-white; display: flex; flex-direction: column; grid-column-start: 1; grid-row-start: 1; justify-content: center; padding: 1rem; text-align: center; transition: 1s; transform: rotateY(-180deg); transform-style: preserve-3d; }
  &__frontside { align-items: center; backface-visibility: hidden; color: $neutral-400; display: flex; flex-direction: column; font-size: 0.875rem; grid-column-start: 1; grid-row-start: 1; padding: 1rem; position: relative; text-align: center; transform: rotateY(0deg); transform-style: preserve-3d; transition: 1s; }
  &:hover &__backside  { transform: rotateY(0deg); }
  &:hover &__frontside { transform: rotateY(180deg); }
  &__image { border-radius: 50%; display: flex; height: 13.5rem; overflow: hidden; width: 13.5rem;
    img { height: 100%; object-fit: cover; width: 100%; }
  }
  &__par { -webkit-box-orient: vertical; -webkit-line-clamp: 2; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; }
  &__title { -webkit-box-orient: vertical; -webkit-line-clamp: 1; color: #262864; display: -webkit-box; font-size: 0.9375rem; font-weight: 600; overflow: hidden; text-overflow: ellipsis; }
  @include media-breakpoint-down(md) {
    &__image { height: 10rem; width: 10rem; }
  }
}

.index { display: flex; flex-wrap: wrap; justify-content: center; margin-left: auto; margin-right: auto; margin-bottom: 1.25rem; max-width: 73rem; width: 90%;
  &__item { background: $base-white; border: 1px solid $neutral-300; color: $neutral-700; margin: 0 -1px -1px 0; padding: 0.375rem 0.75rem;
    &:hover { background: $neutral-200; }
  }
}

.info { display: flex; flex-direction: column; gap: 0.125rem; margin-bottom: 2.3125rem; padding-top: 0.4375rem;
  &__header { background: rgba($blue-500, 0.07); color: $blue-500; display: flex; flex-wrap: wrap; font-size: 1.0625rem; gap: 0.3125rem 0.625rem; padding: 1rem 0.75rem;
		h3 { display: flex; flex-wrap: wrap; gap: 0.3125rem 0.625rem; }
	}
	&__item { background: rgba($blue-500, 0.07); color: $blue-500; font-size: 1.125rem; margin: 0; padding: 0.6875rem 0.75rem 0.9375rem; }
	&__label { background: $base-white; border: 1px solid $neutral-300; border-radius: 0.3125rem; box-shadow: 0.1875rem 0.1875rem 0.25rem rgba($blue-600, 0.15); color: $blue-500; font-size: 0.75rem; padding: 0.25rem 0.75rem 0.3125rem; text-transform: uppercase; }
	@include media-breakpoint-down(sm) {
    &__item { font-size: 1rem; }
	}
}

.link { display: flex; flex-direction: column; gap: 2px; padding-top: 28px;
  &__image { height: 44px; object-fit: contain; width: 50px; }
	&__item { align-items: center; background: rgba($blue-500, 0.07); color: $blue-500; display: flex; font-size: 15px; font-weight: 600; gap: 20px; padding: 10px 22px;
		&::after { content: ">"; opacity: 0.5; }
		&:hover::after { opacity: 1; }
	}
	&__text { flex-grow: 1; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
	@include media-breakpoint-down(xs) {
    &__item { font-size: 14px; gap: 10px; padding-left: 10px; padding-right: 10px; }
	}
}

.list { border-bottom: 0.8125rem solid $blue-500; border-top: 0.8125rem solid $blue-500; line-height: 1.2; padding: 1.75rem 0.625rem 2rem;
  &__heading { color: $blue-500; font-size: 1rem; font-weight: bold; margin: 0; transition: all 0.25s; }
	&__item { display: grid; gap: 1.125rem; grid-template-columns: 5.3125rem 1fr; padding: 1.25rem 0 1.5rem;
    & + & { border-top: 1px solid $neutral-200; }
		&:hover .list__heading { color: $secondary; }
		&-image { border: 1px solid $neutral-200; display: flex; padding: 0.25rem 0;
      > img { height: 100%; object-fit: cover; width: 100%; }
    }
	}
}

.listing { display: flex; flex-direction: column; gap: 1rem; padding-bottom: 0.5rem; position: relative;
  &__contacts { color: $neutral-700; }
  &__header { display: flex; flex-direction: column; left: 0; margin-left: auto; margin-right: auto; max-width: 73rem; position: relative; right: 0; top: 0; z-index: 1; width: 100%; }
  &__header &__item { background: linear-gradient(to bottom right, $neutral-200, $base-white); margin-bottom: 1rem; }
  &__image { display: flex; flex-shrink: 0; width: 16rem; transition: all 0.5s; }
  &__item { align-items: start; background: $base-white; color: $neutral-600; display: flex; gap: 1rem 2rem; font-size: 0.875rem; padding: 0.5rem 1rem; position: relative; transition: all 0.5s;
    a:hover { color: $blue-600; text-decoration: underline; }
    p { margin: 0; }
  }

  &__logo { align-items: center; display: flex; flex-shrink: 0; transition: all 0.5s; width: 7.5rem; }
  &__text { flex-grow: 1; }
  &__title { font-size: inherit; font-weight: bold; margin-bottom: 0; }
    &.compact &__header { position: fixed; }
    &.compact &__header &__image { width: 0; }
    &.compact &__header &__item { align-items: center; flex-direction: row; font-size: 0.75rem; gap: 1rem; margin-bottom: 0; padding-left: 0; }
      &.compact &__header &__logo { width: 3rem; }
      &.compact &__header p { display: none; }
  @include media-breakpoint-down(md) {
    &__header { background: $neutral-200; position: relative; }
    &__image { flex-basis: auto; margin: 0 auto; max-width: 13rem; }
    &__item { flex-direction: column; }
    &__logo { bottom: 0.5rem; max-width: 6rem; position: absolute; right: 1rem; }
    &__text { padding-right: 7rem; }

  }
  @include media-breakpoint-down(sm) {
    &__logo { margin-left: auto; margin-right: auto; position: relative; }
    &__text { padding-right: 0; }
  }
}

.locality { padding-bottom: 1.5rem;
  p { font-size: 0.875rem; line-height: 1.5; margin-bottom: 1.75em; margin-top: 0.25rem; }
	&__contents { display: grid; position: relative;
    &-item { grid-row: 1; grid-column: 1; opacity: 0;
        &.active { opacity: 1; z-index: 5; }
		}
	}
	&__form { display: flex; flex-direction: column; gap: 0.625rem;
    button { background: $blue-700; border: 0; border-radius: 0.3125rem; color: $base-white; font-size: 0.875rem; font-weight: 600; padding: 0.3125rem 1.5rem; text-transform: uppercase;
		&:disabled { opacity: 0.5; }
	}
		input { border: 0.0625rem solid $neutral-200; border-radius: 0.3125rem; font-size: 0.875rem; padding: 0.375rem 0.875rem 0.4375rem; flex-grow: 1; }
    input[type="submit"] { background: $blue-700; border: 0; border-radius: 0.3125rem; color: #fff; font-size: 0.875rem; font-weight: 600; padding: 0.3125rem 1.5rem; text-transform: uppercase; }
		label { text-transform: uppercase; }
		&-column { align-items: flex-start; display: flex; flex-direction: column; gap: 1.1875rem;
			input { align-self: stretch; }
		}
		&-row { align-items: center; display: flex; gap: 0.3125rem 0.9375rem; }
	}
	&__grid { display: grid; gap: 1.25rem 1.75rem; grid-template-columns: 14.125rem 1fr; padding-left: 0.75rem; }
	&__map { border: 1px solid $neutral-300; display: flex; height: 17.25rem; margin-bottom: 1.0625rem; position: relative; width: 100%; }
	&__tabs { display: flex; flex-wrap: wrap; gap: 1rem; margin-bottom: 1.5rem; margin-top: 0.75rem;
    &-item { align-items: center; border: 1px solid $blue-500; color: $blue-500; display: flex; flex-grow: 1; font-size: 0.75rem; font-weight: bold; justify-content: center; padding: 0.75rem 1rem; text-align: center;
			&.active { background: $blue-500; color: $base-white; }
		}
	}
	&__title { color: $neutral-300; font-size: 1.0625rem; text-transform: uppercase; }
	@include media-breakpoint-down(xl) {
    &__grid { grid-template-columns: none; padding-left: 0; }
		&__tabs { flex-wrap: wrap; }
	}
	@include media-breakpoint-down(sm) {
    &__form-row { align-items: stretch; flex-direction: column; }
		&__tabs { gap: 0.3125rem;
			&-item { padding: 0.625rem 0.9375rem 0.5rem; }
		}
	}
}

.max-one-line { -webkit-box-orient: vertical; -webkit-line-clamp: 1; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; }
.max-two-lines { -webkit-box-orient: vertical; -webkit-line-clamp: 2; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; }
.max-three-lines { -webkit-box-orient: vertical; -webkit-line-clamp: 3; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; }
.max-four-lines { -webkit-box-orient: vertical; -webkit-line-clamp: 4; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; }

.main-links { line-height: 1.1; position: sticky; text-align: center; top: 0;
  hr { margin: 0; }
  &__catalog { color: $neutral-300; }
  &__heading { color: $primary; font-size: 1.0625rem; font-weight: bold; text-transform: uppercase; }
  &__inner { display: flex; flex-direction: column; gap: 0.5rem; }
  &__item { align-items: center; background: $neutral-100; color: $neutral-500; display: flex; flex-direction: column; font-size: 1.0625rem; font-weight: bold; justify-content: center; min-height: 4rem; padding: 0.5rem 5rem; position: relative; text-align: center; text-transform: uppercase;
    &:hover { box-shadow: 0.125rem 0.125rem 0.625rem $neutral-300; }
    &::after { color: $primary; content: "\F285"; font: bold 1.25rem bootstrap-icons; position: absolute; right: 0.5rem; }
    &-category { color: $primary; font-weight: normal; }
  }
  &__logo { border: 1px solid $neutral-200; bottom: 0; height: 3rem; left: 0.5rem; object-fit: contain; margin: auto; position: absolute; top: 0; width: 4rem; }
  @include media-breakpoint-down(xx) {
    &__item { font-size: 1rem; }
  }
}

.no-client { line-height: 1.36;
  hr { border-color: $neutral-100; }
  &__address { color: $neutral-500; font-size: 0.875rem; padding-left: 2rem; position: relative;
    &-icon { color: $primary; left: 0; position: absolute; top: 0.25rem; }
  }
  &__banner { background-color: $neutral-100; border: 1px solid $neutral-200; border-left: 0.5rem solid $secondary; display: grid; gap: 1rem; grid-template-areas: "text image"; grid-template-columns: repeat(2,1fr); padding: 0.5rem;
    p { margin: 0; }
    &-image { display: flex; grid-area: image;
      img { object-fit: cover; }
      &-hover { display: flex; position: relative;
        &__text { align-items: center; background: rgba($blue-700,0.75); bottom: 0; color: $base-white; display: flex; font-size: 0.8125rem; justify-content: center; left: 0; opacity: 0; padding: 0.9375rem; position: absolute; right: 0; text-align: center; top: 0; transition: all 0.25s; }
	    &:hover &__text { opacity: 1; }
      }
    }
    &-logo { margin-top: auto; }
    &-more { color: $blue-700; font-weight: bold;}
    &-text { color: $neutral-500; display: flex; flex-direction: column; gap: 0.25rem; grid-area: text; }
    &-title { color: $neutral-500; font-size: 1.125rem; font-weight: bold; margin: 0; }
    &:nth-child(2n) { border-left: 1px solid $neutral-200; border-right: 0.5rem solid $secondary; }
    &:not(&--column):nth-child(2n) { grid-template-areas: "image text"; }
    &--column { grid-template-areas: "image" "text"; grid-template-columns: none; }
    &--primary { border-left-color: $primary;
      &:nth-child(2n) { border-right-color: $primary; }
    }
  }
  &__box { background-color: $base-white; font-size: 0.875rem; margin: 1.25rem 0;
    &--border { border: 1px solid $neutral-100; }
  }
  &__center-title { color: $neutral-600; font-size: 1.25rem; font-weight: 500; }
  &__collapse-toggle { align-items: center; color: $neutral-600; display: flex; font-size: 1.75rem; justify-content: center;
    .bi { transition: transform 0.25s; }
    &.collapsed .bi { transform: rotate(180deg); }
  }
  &__fading-on-hover { color: $neutral-500; font-size: 0.875rem; max-height: 4rem; overflow: hidden; position: relative; transition: max-height 0.5s ease-out; padding-top: 0.5rem; position: relative;
    &::after { background: linear-gradient(rgba($base-white,0),$base-white); bottom: 0; content: ""; height: 2rem; left: 0; opacity: 1; position: absolute; transition: all 0.25s; width: 100%; }
    &:hover { max-height: 31.25rem; transition: max-height 0.5s ease-in;
      &::after { opacity: 0; }
    }
  }
  &__published-at { color: #ccc; text-align: right; }
  &__heading { background: $primary; color: $base-white; font-size: 0.875rem; padding: 0.625rem; text-align: center; text-transform: uppercase;
    &--white { background: $base-white; border-bottom: 1px solid $neutral-100; color: $neutral-500; text-align-last: left; }
  }
  &__image { border: 1px solid $neutral-100; }
  &__image--post-image { width: 90px; }
  &__label { background: url("img/no-client__label.png") no-repeat; background-size: auto 100%; color: $base-white; font-size: 1.125rem; font-weight: 600; height: 94px; line-height: 1; margin-left: -49px; padding: 12px 10px 0 10px; text-align: center; text-transform: uppercase; width: 150px; }
  &__languages { color: $neutral-300; display: flex; font-size: 0.875rem; gap: 0.25rem; justify-content: end; line-height: 1;
    &-item { border-left: 1px solid $neutral-200; padding-left: 0.25rem;
      &a:hover { color: $blue-500; }
      &:first-child { border-left: none; padding-left: 0; }
    }
  }
  &__link:not(:hover) { text-decoration: underline; }
  &__name { color: $neutral-600; font-size: 0.875rem; font-weight: bold;
    a:hover { color: $blue-600; text-decoration: underline; }
  }
  &__perex { color: $neutral-600; font-size: 1rem; font-weight: 500; margin-bottom: 0.625rem; }
  &__row { display: grid; gap: 1rem; grid-template-columns: repeat(2,1fr); }
  &__tabs { display: flex;
    &-item { background: $primary; color: $base-white; flex: 1 0 0; font-size: 0.875rem; padding: 0.625rem; text-align: center; text-transform: uppercase;
      &:hover { background: $blue-600; color: $base-white; }
      &.active { background: $base-white; color: $primary; }
    }
  }
  &__text { color: $neutral-500; font-size: 0.875rem; margin-bottom: 0.625rem;
    a:hover { color: $blue-500; }
    ul { padding-left: 1rem; }
  }
  &__title { color: $neutral-500; font-size: 1rem; font-weight: 500; margin: 0; margin-bottom: 0.9375rem; text-transform: uppercase; }
  &__transparent { color: $neutral-500; font-size: 0.875rem; max-height: 6rem; overflow: hidden; position: relative; transition: max-height 0.5s ease-out; padding-top: 0.5rem; position: relative;
    &::after { background: linear-gradient(rgba($base-white,0),$base-white); bottom: 0; content: ""; height: 2rem; left: 0; opacity: 1; position: absolute; transition: all 0.25s; width: 100%; }
  }
  &__qr { max-width: 8rem; }
  &__carousel {
	&-item { height: 200px; background: #999; text-align: center; position: relative;
	  img { height: 100%; }
	}
	
	&-item-description { position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%); text-align: center; background: rgba(50, 50, 50, 0.60); color: #ddd; max-width: 75%; }
  }
  @include media-breakpoint-down(md) {
    &__row { grid-template-columns: none; }
  }
  @include media-breakpoint-down(lg) {
    &__banner-title { font-size: 1rem; }
    &__box--md-hidden { display: none; }
  }
}

.scroll-up { align-items: center; background-color: $neutral-400; border-radius: 0.25rem; border: none; bottom: 1.25rem; color: white; display: none; font-size: 1.125rem; height: 3rem; justify-content: center; outline: none; position: fixed; right: 1.875rem; text-align: center; width: 3rem; z-index: 999;
  &:hover { background-color: $neutral-600; color: $base-white; }
  &-visible { display: flex; }
}

.services { background: $neutral-100; border-top: 0.4375rem solid; color: $blue-500; display: flex; flex-direction: column; gap: 0.125rem;
  &__heading { flex-grow: 1; font-size: 1rem; font-weight: bold; margin: 0; text-transform: uppercase; }
	&__image { flex-shrink: 0; width: 4.375rem;
    img {height: 100%; object-fit: cover; width: 100%; }
  }
	&__item { align-items: center; background: $base-white; display: flex; gap: 1.25rem; grid-template-columns: 1fr 6.75rem; padding: 1.375rem 1.375rem 1.5rem 2rem;
    &:hover { color: $secondary; }
	}
	@include media-breakpoint-down(xl) {		&__item { grid-template-columns: 1fr 4.375rem; padding: 1.25rem; }
	}
}

.sidemenu { bottom: 0; position: fixed; right: 0; top: 0; z-index: 1;
  a:hover { text-decoration: underline; }
  &__buttons { border-bottom: 1px solid $neutral-100; display: flex; flex-direction: column; gap: 1rem; margin-bottom: 1rem; padding-bottom: 1rem; }
  &__content { background: $base-white; display: flex; flex-direction: column; height: 100%; margin-right: -15.5rem; overflow: auto; padding: 1rem; transition: all 0.25s; width: 15.5rem; }
  &__form { border-bottom: 1px solid $neutral-100; display: flex; flex-direction: column; gap: 0.5rem; margin-bottom: 1rem; padding-bottom: 1rem;
    &-check { align-items: start; display: flex; gap: 0.5rem;
      input { margin-top: 1px; transform: scale(0.85); }
      label { color: $neutral-400; cursor: pointer; font-size: 0.75rem; }
    }
  }
  &__nav { display: flex; flex-direction: column;
	&:not(&--global) { margin-top: auto; }
    &-item { align-items: center; border-bottom: 1px solid $neutral-100; color: $neutral-600; display: flex; font-size: 0.75rem; font-weight: bold; justify-content: center; min-height: 2.5rem; padding: 0.5rem 1rem; text-align: center; text-transform: uppercase; }
  }
  &__regions { border-bottom: 1px solid $neutral-100; color: $neutral-600; display: flex; flex-direction: column; font-size: 0.75rem; gap: 0.25rem; margin-bottom: 1rem; padding-bottom: 1rem; }
  &__social { display: flex; gap: 1rem; justify-content: center; margin-bottom: auto; margin-top: 1rem;
    &-item { display: flex; width: 2.5rem; }
  }
  &__toggler { align-items: center; background: $neutral-100; border: none; border-radius: 0.375rem 0 0 0.375rem; bottom: 0; box-shadow: 0 2px 5px 0 rgba($base-black,0.5); color: $neutral-400; display: flex; font: 0.875rem $font-family-roboto; gap: 1rem; height: 3.5rem; justify-content: center; margin: auto 0; padding: 0.5rem 1rem; position: absolute; right: 0; top: 0; text-align: left; text-transform: uppercase; width: 10rem; z-index: 1;
    .fa-solid { font-size: 1rem; margin-left: 0; }
    &:hover { width: 12rem; }
  }
  &__vip { display: flex; flex-direction: column; gap: 0.25rem; margin-bottom: auto; }
  &.active &__content { margin-right: 0; }
  &.active &__toggler { background: rgba($primary, 0.5); border-radius: 0 0.375rem 0.375rem 0; color: $base-white; font-size: 0; gap: 0; height: 3rem; left: 0; padding: 0.5rem; right: auto; width: 3rem;
    &:hover { background: $primary; }
  }
  &--left { left: 0; right: auto; }
  &--left &__content { margin-left: -15.5rem; margin-right: 0; }
  &--left &__toggler { border-radius: 0 0.375rem 0.375rem 0; left: 0; right: auto; }
  &--left.active &__content { margin-left: 0; }
  &--left.active &__toggler { border-radius: 0.375rem 0 0 0.375rem; left: auto; right: 0; }
  @include media-breakpoint-down(lg) {
    &__toggler { font-size: 0; gap: 0; height: 3rem; padding: 0.5rem; width: 3rem; }
  }
}

.subpage { background: $base-white; border-radius: 0.25rem; display: flex; flex-direction: column; gap: 1rem; margin-left: auto; margin-right: auto; max-width: 73rem; padding: 1.25rem 1rem; position: relative; text-align: center; width: 90%;
  &::before, &::after { border-radius: 100px / 10px; bottom: 0; box-shadow: 0 0 20px rgba($base-black, 0.2); content: ""; left: 10px; position: absolute; right: 10px; top: 50%; z-index: -1; }
  &__container { color: $neutral-500; font-size: 0.875rem; margin-left: auto; margin-right: auto; max-width: 45rem; text-align: left; }
  &__controls { border-top: 1px solid $neutral-100; border-bottom: 1px solid $neutral-100; padding-bottom: 1.25rem; padding-top: 1.25rem;
    &-buttons { display: flex; gap: 1rem; justify-content: center;
      .btn { padding-left: 2.25rem; padding-right: 2.25rem; text-transform: uppercase; }
    }
    &-toggler { background-color: $neutral-100; border: none; box-shadow: rgba($base-black, 0.12) 0px 1px 3px, rgba($base-black, 0.24) 0px 1px 2px; color: #333; }
  }
  &__menu { border-bottom: 2px solid $neutral-100; border-top: 2px solid $neutral-200; display: flex; flex-wrap: wrap; gap: 0.5rem 1rem; justify-content: center; padding: 1rem 0;
    &-item { border-right: 2px solid $neutral-300; color: $neutral-400; line-height: 1; padding-right: 1rem; text-transform: uppercase;
      &:last-child { border-right: none; padding-right: 0; }
	}
  }
  &__subtitle { color: $neutral-600; font-size: 0.875rem; font-weight: normal; margin: 0 auto; max-width: 60rem; }
  &__title { color: $neutral-600; font-size: 1.625rem; font-weight: normal; margin: 0; text-transform: uppercase; }
  @include media-breakpoint-down(lg) {
    &__controls-buttons .btn { padding-left: 1rem; padding-right: 1rem; }
  }
}

.superbanner { background: $primary; color: $neutral-500; display: grid; height: 28rem; text-align: center;
  p { margin: 0; }
  &__carousel { grid-column-start: 1; grid-row-start: 1; height: 100%; position: relative;
    &-heading { font-size: 0.875rem; font-weight: bold; }
    &-image { display: flex; padding: 0.25rem;
      img { height: 100%; object-fit: contain; width: 100%; }
    }
    &-item { animation-duration: 20s; animation-iteration-count: infinite; background: $base-white; display: grid; grid-template-rows: 2fr 0.5fr 1fr; height: 100%; position: absolute; transform: scale(0);
      &.active { transform: scale(1); }
    }
    &-logo { align-items: center; display: flex; justify-content: center; padding: 0.25rem; }
    &-text { align-items: center; border-top: 1px solid $neutral-100; display: flex; flex-direction: column; font-size: 0.875rem; justify-content: center; }
  }
  &__grid { display: grid; gap: 2px; grid-column-start: 1; grid-row-start: 1; grid-template-columns: repeat(2,1fr); height: 100%;
    &-vertical { grid-template-columns: repeat(1,1fr); }
    &-item { background: $base-white; display: flex; padding: 0.25rem;
      img { height: 100%; object-fit: contain; width: 100%; }
    }
  }
}
