.transition-dim {
	position: relative;

	&::before {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 9999;
		border-radius: inherit;
		background: rgba(211, 211, 211, 0.3);
		opacity: 0;
		content: '';
		transform: scale(0, 0);
	}

	&.transition-active::before,
	&.transition-middle::before {
		transform: none;
	}

	&.transition-out {
		transition: opacity .3s;
	}

	&.transition-out::before {
		transition: opacity .3s;
		opacity: 1;
	}

	&.transition-in {
		transition: opacity .2s;
	}

	&.transition-in::before {
		transition: opacity .2s;
		opacity: 0;
	}
}

#snippet--content.transition-dim {
	&::before {
		position: fixed;
	}
}
